<template>
  <div class="uploadBankCard">
    <div>
      <mt-field
        :label="$t('name')"
        disabled
        :placeholder="$t('namePlaceholder')"
        v-model="form.name"
        :state="nameState"
        @blur.native.capture="BEHAVIOR_ADD({
          id: `${behavior}_C01_I_NAME`,
          newValue: form.name
        })"
      ></mt-field>
      <mt-radio
        class="mtRadio"
        :title="$t('bankCard')"
        v-model="form.cardNoType"
        @change="BEHAVIOR_ADD({
          id: `${behavior}_C01_I_CARDNOTYPE`,
          newValue: form.cardNoType
        })"
        :options="[{
          label: $t('bankCardNumber'),
          value: 'CARD'
        }, {
          label: $t('bankAccountNumber'),
          value: 'ACCOUNT'
        }]">
      </mt-radio>
      <mt-field
        v-if="form.cardNoType == 'CARD'"
        :label="$t('bankCardNumber')"
        :placeholder="$t('bankCardNumberPlaceholder')"
        v-model="form.maskedBankCardNo"
        v-mask="'#### #### #### ####'"
        :state="maskedBankCardNoState"
        @blur.native.capture="BEHAVIOR_ADD({
          id: `${behavior}_C05_I_BANKCARDNO`,
          newValue: form.maskedBankCardNo
        })"
      ></mt-field>
      <mt-field
        v-else
        :label="$t('bankAccountNumber')"
        :placeholder="$t('bankAccountNumberPlaceholder')"
        v-model="form.maskedBankCardNo"
        :state="maskedBankCardNoState"
        @blur.native.capture="BEHAVIOR_ADD({
          id: `${behavior}_C05_I_BANKCARDNO`,
          newValue: form.maskedBankCardNo
        })"
      ></mt-field>
      <mt-field
        :label="$t('bank')"
        :placeholder="$t('bankPlaceholder')"
        :state="bankCodeState"
        readonly
        v-model="(Enum.bank.filter(e => e.code == form.bankCode)[0]||{}).name"
        @click.native="onPopupPicker(Enum.bank, (e) => {
          form.bankCode = e.code;
          BEHAVIOR_ADD({
            id: `${behavior}_C02_S_BANKCODE`,
            newValue: form.bankCode
          })
        })"
      >
        <i class="iconfont">&#xe62a;</i>
      </mt-field>
    </div>
    <popup-picker ref="popupPicker" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { emptyRules, nameRules } from '../../utils/rules';
import popupPicker from '@/components/popupPicker.vue'
import mixin from './mixin'
export default {
  name: 'UploadBankCard',
  mixins: [mixin],
  props: {
    behavior: {
      type: String,
      default: 'P07'
    }
  },
  components: {
    popupPicker
  },
  data() {
    return {
      isCheck: false,
      form: {
        name: '',
        cardNoType: '',
        bankCardNo: '',
        maskedBankCardNo: '',
        bankCode: '',
        bankName: '',
      }
    }
  },
  computed: {
    ...mapState(["apply"]),
    nameState() {
      this.onStorageItem('name')
      return this.isCheck ? nameRules(this.form.name) : null;
    },
    maskedBankCardNoState() {
      this.onStorageItem('maskedBankCardNo')
      return this.isCheck ? emptyRules(this.form.maskedBankCardNo) : null;
    },
    bankCodeState() {
      this.onStorageItem('bankCode')
      return this.isCheck ? emptyRules(this.form.bankCode) : null;
    }
  },
  watch: {
    form: {
      handler(val) {
        this.$emit('input', val)
      },
      immediate: true,
      deep: true
    },
    'form.cardNoType': {
      handler() {
        this.onStorageItem('cardNoType')
      }
    },
    'form.maskedBankCardNo': {
      handler(val) {
        this.form.bankCardNo = (val||'').replace(/\s/g, '')
      },
      immediate: true
    }
  },
  created() {
    Object.keys(this.form).forEach((val) => {
      this.form[val] = this.apply[val]
    })
    if (!this.form.cardNoType) {
      this.form.cardNoType = 'ACCOUNT'
    }
    this.init()
  },
  methods: {
    ...mapMutations('behavior', ["BEHAVIOR_ADD"]),
    ...mapActions('behavior', ["BEHAVIOR_SEND"]),
    init() {
      this.GET_BANK(); // 获取银行
    },
    validation() {
      this.isCheck = true
      if (this.maskedBankCardNoState != "success") {
        if (this.form.cardNoType == 'CARD') {
          return this.$t('bankCardNumberError')
        } else {
          return this.$t('bankAccountNumberError')
        }
      }
      if (this.bankCodeState != "success") {
        return this.$t('bankError')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.uploadBankCard {
  .iconfont {
    font-size: 18px;
  }
  .mtRadio {
    overflow: hidden;
    background-image: linear-gradient( 180deg, #d9d9d9, #d9d9d9 50%, transparent 50%);
    background-size: 120% 1px;
    background-repeat: no-repeat;
    background-position: top left;
    margin-left: 8px;
    /deep/ .mint-cell:last-child {
      background-image: none;
    }
  }
  /deep/ .mint-cell-wrapper {
    background-origin: content-box;
  }
  /deep/ a:first-child .mint-cell-wrapper {
    background-origin: border-box;
  }
}
</style>