<template>
  <mt-popup
    class="idcardExample"
    v-model="popupVisible"
  >
    <div class="box">
      <div class="close" @click="popupVisible = false">
        <img src="../assets/close.png">
      </div>
      <div class="comtent">
        <div class="title">{{ $t('example') }}</div>
        <div class="val">
          <div>{{ $t('exampleTitle1') }}</div>
          <img src="../assets/idcard_example_1.png">
          <div>{{ $t('exampleTitle2') }}</div>
          <img src="../assets/idcard_example_2.png">
          <div>{{ $t('exampleTitle3') }}</div>
          <img src="../assets/idcard_example_3.png">
        </div>
        <div class="tips">{{ $t('exampleTips') }}</div>
        <div class="info">{{ $t('exampleinfo1') }}</div>
        <div class="info">{{ $t('exampleinfo2') }}</div>
        <div class="info">{{ $t('exampleinfo3') }}</div>
      </div>
    </div>
  </mt-popup>
</template>

<script>
export default {
  name: 'IdcardExample',
  data () {
    return {
      popupVisible: false,
    }
  }
}
</script>

<style lang="scss" scoped>
.idcardExample {
  border-radius: 10px;
  background-color: transparent;
  .box {
    width: 100vw;
    height: 90vh;
    .close {
      height: 50px;
      img {
        position: absolute;
        width: 30px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .comtent {
      overflow: auto;
      position: absolute;
      top: 50px;
      left: 10px;
      right: 10px;
      bottom: 0px;
      background-color: white;
      border-radius: 10px;
      padding-bottom: 10px;
      .title {
        color: #296DB8;
        font-size: 12px;
        padding: 15px 10px;
        text-align: center;
      }
      .val {
        text-align: center;
        font-size: 12px;
        color: #111111;
        img {
          height: 85px;
          margin: 4px 10px 10px 10px;
        }
      }
      .tips {
        font-size: 13px;
        color: #4F9B41;
        padding: 0px 6px;
        line-height: 20px;
        &::before {
          content: '';
          width: 20px;
          height: 20px;
          background-image: url(../assets/warning.png);
          background-size: 100%;
          background-repeat: no-repeat;
          display: inline-block;
          vertical-align: bottom;
          margin-right: 5px;
        }
      }
      .info {
        color: #322929;
        font-size: 12px;
        margin: 4px 16px;
      }
    }
  }
}
</style>