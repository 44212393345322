<template>
  <div class="uploadImage">
    <div class="idcard">
      <update-file type="INE_OR_IFE_FRONT" :is-supplement="isSupplement" :tipContent="$t('idcardFrontTip')" @change="onUploadIdcardFront">
        <img src="../../assets/idcard.png">
      </update-file>
      <div class="error">{{ idcardFrontState }}</div>
    </div>


    <div class="wrap">
      <update-file type="INE_OR_IFE_BACK" :is-supplement="isSupplement" :tipContent="$t('idcardBackTip')" @change="onUploadHandheldIdcard">
        <img src="../../assets/idcard.png">
      </update-file>
      <div class="error">{{ handheldIdcardState }}</div>
    </div>

    <div class="tips">{{ $t('idcardAuthTips') }}</div>
    <mt-popup v-if="popupVisible" v-model="popupVisible" popup-transition="popup-fade">
      <div class="demopic">
        <mt-swipe :auto="0" :defaultIndex="defaultIndex">
          <mt-swipe-item v-for="index in 4" :key="index">
            <img :src="require(`../../assets/uploaddemo/uploaddemo-${index}-a.png`)">
            <div class="txt">{{$t(`idcardExampleList${index}`)}}</div>
          </mt-swipe-item>
        </mt-swipe>
        <div class="ok border-t" @click="popupVisible = false">{{$t('confirm')}}</div>
      </div>
    </mt-popup>
    <idcard-example ref="idcardExample" />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import EXIF from 'exif-js'
import updateFile from '@/components/updateFile.vue'
import idcardExample from '@/components/idcardExample.vue'
export default {
  name: 'UploadImage',
  props: {
    behavior: {
      type: String,
      default: 'P05'
    },
    isSupplement: {
      type: String,
      default: 'N'
    }
  },
  components: {
    updateFile,
    idcardExample,
  },
  data() {
    return {
      isCheck: false,
      popupVisible: false,
      defaultIndex: 0,
      idcardFront: null,
      handheldIdcard: null,
    }
  },
  computed: {
    list() {
      return [
        this.idcardFront,
        this.handheldIdcard,
      ].filter(e => e);
    },
    idcardFrontState() {
      return this.isCheck ? this.idcardFront? null: this.$t('idcardFrontError') : null
    },
    handheldIdcardState() {
      return this.isCheck ? this.handheldIdcard? null: this.$t('handheldIdcardError') : null
    },
  },
  methods: {
    ...mapMutations('behavior', ["BEHAVIOR_ADD"]),
    validation() {
      this.isCheck = true
      if (this.idcardFrontState) {
        return this.idcardFrontState
      }
      if (this.handheldIdcardState) {
        return this.handheldIdcardState
      }
    },
    onUploadIdcardFront(id, file) {
      this.idcardFront = id
      this.BEHAVIOR_ADD({
        id: `${this.behavior}_C01_I_ID`,
        newValue: id
      })
      let self = this
      EXIF.getData(file.origin, function() {
        let data = EXIF.getAllTags(this)
        if (data && data.MakerNote && data.MakerNote.length > 100) {
          data.MakerNote = data.MakerNote.slice(0, 100)
        }
        self.BEHAVIOR_ADD({
          id: `${self.behavior}_C01_E_EXIF`,
          newValue: JSON.stringify(data)
        })
      })
      this.$emit('input', this.list)
    },
    onUploadHandheldIdcard(id, file) {
      this.handheldIdcard = id
      this.BEHAVIOR_ADD({
        id: `${this.behavior}_C02_I_ID`,
        newValue: id
      })
      let self = this
      EXIF.getData(file.origin, function() {
        let data = EXIF.getAllTags(this)
        if (data && data.MakerNote && data.MakerNote.length > 100) {
          data.MakerNote = data.MakerNote.slice(0, 100)
        }
        self.BEHAVIOR_ADD({
          id: `${self.behavior}_C02_E_EXIF`,
          newValue: JSON.stringify(data)
        })
      })
      this.$emit('input', this.list)
    },
    setDemoPic(val) {
      this.defaultIndex = val - 1
      this.popupVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.uploadImage {
  .idcard {
    height: 200px;
    width: 327px;
    margin: 0 auto;
  }

  .tip {
    margin-top: 5px;
    height: 50px;
    color: #FFFFFF;
    font-size: 15px;
    text-align: center;
  }
  .error {
    margin-top: 5px;
    color: red;
    font-size: 14px;
    text-align: center;
  }
  .border {
    margin: 35px 10px 10px;
    position: relative;
    padding: 20px 5px 20px 5px;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    .content {
      display: flex;
      .item {
        padding: 0 5px;
        flex: 1;
        text-align: center;
        position: relative;
        img {
          width: 30%;
        }
        .txt {
          text-align: center;
          font-size: 12px;
          word-break: break-all;
        }
      }
    }
  }
  .wrap {
    height: 200px;
    width: 327px;
    margin: 50px auto;
  }

  /deep/ .demopic {
    width: 250px;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    text-align: center;
    img {
      width: 50%;
    }
    .mint-swipe {
      height: 250px;
    }
    .mint-swipe-indicator.is-active {
      background-color: #27a2ff;
    }
    .ok {
      text-align: center;
      color: #27a2ff;
      line-height: 40px;
    }
  }
  > .tips {
    margin: 30px 30px 0px 30px;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
  }
}
</style>