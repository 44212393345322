<template>
  <div class="supplementary">
    <mt-header :title="title">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)">
          <img src="@/assets/back.png" height="20" width="20" slot="icon">
          {{ $t('back') }}
        </mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="tips">{{ tips }}</div>
    <upload-image ref="uploadImage" v-if="isSupplementImage" v-model="images" behavior="P08" is-supplement="Y" />
    <upload-bank-card ref="uploadBankCard" v-if="isSupplementBank" v-model="paramsBank" behavior="P08" />
    <div class="bottom">
      <mt-button type="primary" size="large" @click="submit">{{
        $t("confirm")
      }}</mt-button>
    </div>
  </div>
</template>

<script>
import { supplementSubmit } from '../../utils/api'
import callPhone from '@/components/callPhone.vue'
import uploadImage from './UploadImage.vue'
import uploadBankCard from './UploadBankCard.vue'
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  name: 'Supplementary',
  components: {
    callPhone,
    uploadImage,
    uploadBankCard,
  },
  data() {
    return {
      images: [],
      paramsBank: {}
    }
  },
  mounted() {
    this.BEHAVIOR_ADD({
      id: 'P08_C00'
    })
  },
  destroyed() {
    this.BEHAVIOR_ADD({
      id: 'P08_C99'
    })
    this.BEHAVIOR_SEND()
  },
  computed: {
    ...mapState(["user"]),
    title() {
      if (this.user.applyStatus == "SUPPLEMENT_IMAGE") {
        return this.$t('supplementaryImage')
      } else if (this.user.applyStatus == "SUPPLEMENT_BANK_CARD") {
        return this.$t('supplementaryBankCard')
      }
      return this.$t('supplementaryInformation')
    },
    tips() {
      if (this.user.applyStatus == "SUPPLEMENT_IMAGE") {
        return this.$t('supplementaryImageTips')
      } else if (this.user.applyStatus == "SUPPLEMENT_BANK_CARD") {
        return this.$t('supplementaryBankCardTips')
      }
      return this.$t('supplementaryInformationTips')
    },
    isSupplementImage() {
      if (this.user.applyStatus == "SUPPLEMENT_IMAGE" || this.user.applyStatus == "SUPPLEMENT_IMAGE_CARD") {
        return true
      }
      return false
    },
    isSupplementBank() {
      if (this.user.applyStatus == "SUPPLEMENT_BANK_CARD" || this.user.applyStatus == "SUPPLEMENT_IMAGE_CARD") {
        return true
      }
      return false
    },
  },
  methods: {
    ...mapMutations('behavior', ["BEHAVIOR_ADD"]),
    ...mapActions('behavior', ["BEHAVIOR_SEND"]),
    async submit() {
      var uploadImageError;
      var uploadBankCardError;
      if (this.isSupplementImage) {
        uploadImageError = this.$refs.uploadImage.validation()
      }
      if (this.isSupplementBank) {
        uploadBankCardError = this.$refs.uploadBankCard.validation()
      }
      if (uploadImageError) return this.$toast(uploadImageError)
      if (uploadBankCardError) return this.$toast(uploadBankCardError)

      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start();

      await this.$axios({
        method: "post",
        url: supplementSubmit,
        data: {
          applyId: this.user.applyId,
          images: this.images.map(num => ({
            imageId: num
          }))
        },
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.$router.back(-1)
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});

      this.$indicator.close();
      this.$NProgress.done();
    }
  }
}
</script>

<style lang="scss" scoped>
.supplementary {
  .tips {
    margin: 20px;
    color: #ec4242;
    font-size: 12px;
  }
  .bottom {
    padding: 20px 20px;
  }
}
</style>